<template>
  <div class='indexbox'>
    <div class="searchbox">
      <div class="tablebox" v-loading='loading'>
        <el-form ref="forms" :model="form" :rules="rules" label-width="380px">
          <el-form-item label="Default Assembly Location" prop="warehouse_id">
            <el-select v-model="form.warehouse_id" placeholder="Please select" filterable>
              <el-option :label="val.Name" :value="val.ID" v-for="val in location" :key="val.ID"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="In Transit Account" prop="in_transit_account">
            <el-select v-model="form.in_transit_account" placeholder="Please select" filterable>
              <el-option :label="val.DisplayName" :value="val.Code" v-for="val in productAccount" :key="val.Code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Work In Progress Account" prop="in_progress_account">
            <el-select v-model="form.in_progress_account" placeholder="Please select" filterable>
              <el-option :label="val.DisplayName" :value="val.Code" v-for="val in productAccount" :key="val.Code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Finished Goods Account" prop="finished_goods_account">
            <el-select v-model="form.finished_goods_account" placeholder="Please select" filterable>
              <el-option :label="val.DisplayName" :value="val.Code" v-for="val in productAccount" :key="val.Code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Stock Adjustment Expense Account" prop="stock_account">
            <el-select v-model="form.stock_account" placeholder="Please select" filterable>
              <el-option :label="val.DisplayName" :value="val.Code" v-for="val in stockAccount" :key="val.Code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Enable ' Maximum Assembly Order Qty' Controlling" prop="max_num_open">
            <el-switch v-model="form.max_num_open" :active-value="1" :inactive-value="0" active-color='#19CBBF'>
            </el-switch>
          </el-form-item>
          <el-form-item label="Maximum Assembly Order Qty" prop="max_num">
            <el-input v-model="form.max_num" placeholder="Please enter"></el-input>
          </el-form-item>
          <el-form-item label="Zero Stock Adjustment Batch NO" prop="zero_stock_no">
            <el-input v-model="form.zero_stock_no" placeholder="Please enter"></el-input>
          </el-form-item>
          <el-form-item label="Stock Adjustment Reference" prop="zero_stock_remark">
            <el-input v-model="form.zero_stock_remark" placeholder="Please enter"></el-input>
          </el-form-item>
          <el-form-item label="API">
            <el-input v-model="form.api_account" disabled></el-input>
          </el-form-item>
          <el-form-item label="Allocate API Aaccount" prop="api_account2">
            <el-input v-model="form.api_account2" placeholder="Please enter"></el-input>
          </el-form-item>
          <el-form-item label="Allocate API Key" prop="api_key2">
            <el-input v-model="form.api_key2" placeholder="Please enter"></el-input>
          </el-form-item>
          <el-form-item label="Complete API Aaccount" prop="api_account3">
            <el-input v-model="form.api_account3" placeholder="Please enter"></el-input>
          </el-form-item>
          <el-form-item label="Complete API Key" prop="api_key3">
            <el-input v-model="form.api_key3" placeholder="Please enter"></el-input>
          </el-form-item>
          <el-form-item label="To Email" prop="to_email">
            <el-input v-model="form.to_email" placeholder="Please enter"></el-input>
          </el-form-item>
          <el-form-item class="btnbox" v-hasPermi="['ss']">
            <el-button type="primary" @click.native.prevent="onSubmit()" :loading='btn_loading'>Save</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['api_id']),
  },
  data() {
    return {
      tablist: [],
      activeName: '',
      loading: false,
      form: {
        warehouse: '',
        warehouse_id: '',
        in_transit_account: '',
        in_progress_account: '',
        finished_goods_account: '',
        stock_account: '',
        max_num_open: '',
        max_num: '',
        zero_stock_no: '',
        zero_stock_remark: '',
        // order_start_time: '',
        api_account: '',
        api_account2: '',
        api_key2: '',
        api_account3: '',
        api_key3: '',
        to_email: '',
      },
      rules: {
        warehouse_id: [
          { required: true, trigger: "blur", message: 'Please Select' }
        ],
        in_transit_account: [
          { required: true, trigger: "blur", message: 'Please Select' }
        ],
        in_progress_account: [
          { required: true, trigger: "blur", message: 'Please Select' }
        ],
        finished_goods_account: [
          { required: true, trigger: "blur", message: 'Please Select' }
        ],
        stock_account: [
          { required: true, trigger: "blur", message: 'Please Select' }
        ],
        max_num_open: [
          { required: true, trigger: "blur", message: 'Please Select' }
        ],
        max_num: [
          { required: true, trigger: "blur", message: 'Please Enter' }
        ],
        zero_stock_no: [
          { required: true, trigger: "blur", message: 'Please Enter' }
        ],
        zero_stock_remark: [
          { required: true, trigger: "blur", message: 'Please Enter' }
        ],
        // order_start_time: [
        //   { required: true, trigger: "blur", message: 'Please Select' }
        // ],
        // api_account2: [
        //   { required: true, trigger: "blur", message: 'Please Enter' }
        // ],
        // api_key2: [
        //   { required: true, trigger: "blur", message: 'Please Enter' }
        // ],
        // api_account3: [
        //   { required: true, trigger: "blur", message: 'Please Enter' }
        // ],
        // api_key3: [
        //   { required: true, trigger: "blur", message: 'Please Enter' }
        // ],
      },
      location: [],
      productAccount: [],
      stockAccount: [],
      btn_loading: false,
    };
  },
  methods: {
    getlist() {
      this.loading = true
      this.$post({
        url: "/app/dear/location",
        data: {
          api_id: this.api_id,
        },
        success: (res) => {
          this.location = res.data
          this.getdata()
        },
        tip: () => { },
      })
      this.$post({
        url: "/app/dear/productAccount",
        data: {
          api_id: this.api_id,
        },
        success: (res) => {
          this.productAccount = res.data
        },
        tip: () => { },
      })
      this.$post({
        url: "/app/dear/stockAccount",
        data: {
          api_id: this.api_id,
        },
        success: (res) => {
          this.stockAccount = res.data
        },
        tip: () => { },
      })
    },
    getdata() {
      this.$post({
        url: "/app/api_config/info",
        data: {
          api_id: this.api_id
        },
        success: res => {
          this.$nextTick(() => {
            this.form = {
              warehouse: res.data.warehouse,
              warehouse_id: res.data.warehouse_id,
              in_transit_account: res.data.in_transit_account,
              in_progress_account: res.data.in_progress_account,
              finished_goods_account: res.data.finished_goods_account,
              stock_account: res.data.stock_account,
              max_num_open: res.data.max_num_open,
              max_num: res.data.max_num,
              zero_stock_no: res.data.zero_stock_no,
              zero_stock_remark: res.data.zero_stock_remark,
              // order_start_time: res.data.order_start_time,
              api_account: res.data.api_account,
              api_account2: res.data.api_account2,
              api_key2: res.data.api_key2,
              api_account3: res.data.api_account3,
              api_key3: res.data.api_key3,
              to_email: res.data.to_email,
            }
          })
          this.loading = false
        },
        tip: () => {
          this.loading = false
          this.form = {
            warehouse: '',
            warehouse_id: '',
            in_transit_account: '',
            in_progress_account: '',
            finished_goods_account: '',
            stock_account: '',
            max_num_open: '',
            max_num: '',
            zero_stock_no: '',
            zero_stock_remark: '',
            // order_start_time: '',
            api_account: '',
            api_account2: '',
            api_key2: '',
            api_account3: '',
            api_key3: '',
            to_email: '',
          }
        },
      })
    },
    onSubmit() {
      this.$refs.forms.validate(valid => {
        if (valid) {
          let warehouse_obj = this.location.find(data => data.ID == this.form.warehouse_id)
          this.form.warehouse = warehouse_obj.Name
          this.btn_loading = true
          this.$post({
            url: "/app/api_config/setConfig",
            data: {
              api_id: this.api_id,
              ...this.form
            },
            success: (res) => {
              this.$message({
                message: 'Success',
                type: 'success'
              });
              setTimeout(() => {
                this.getdata()
                this.btn_loading = false
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.btn_loading = false
              }, 1500);
            },
          })
        }
      });
    },
  },
  created() {
    this.getlist()
  },
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  height: 100%;

  .searchbox {
    width: 100%;
    padding: 10px 20px 0px;
    background: #fff;
    border-bottom: 1px solid #babdcd;
  }

  .tablebox {
    width: 100%;
    padding: 10px 10px 20px;

    .tit {
      color: #717988;
      font-size: 14px;
      line-height: 1.4;
      margin-top: 5px;
    }

    .btnbox {
      /deep/.el-form-item__content {
        padding-left: 260px;

        .el-button {
          min-width: 200px;
          max-width: 40%;
        }
      }
    }
  }

  /deep/.el-form-item {
    .el-form-item__content {

      .el-select,
      .el-button,
      .el-date-editor {
        width: 100%;
      }
    }
  }
}
</style>